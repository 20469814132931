import React from 'react'
import configuredAxios from 'lib/axios'
import { proxyUrl } from 'lib/utils/request'

import { ReactQueryConfigProvider } from 'react-query'

/**
 * This config sets up the Provider to proxy `react-query` requests _by default_
 * i.e. we no longer need to build a proxied url for API calls :tada:
 */
const reactQueryConfig = {
  queries: {
    queryFn: async key => {
      if (!key) return
      const { data } = await configuredAxios.get(proxyUrl({ link: key }))
      return data
    },
  },
}

const ConfiguredReactQueryProvider = ({ children }) => (
  <ReactQueryConfigProvider config={reactQueryConfig}>
    {children}
  </ReactQueryConfigProvider>
)

export default ConfiguredReactQueryProvider
