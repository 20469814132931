import { MASK_TYPES } from 'components/MaskedAnimalIdInput'

const ANIMAL_TIMELINE_AHB_NUMBER = 'ahbIdentifier'
const ANIMAL_TIMELINE_BIRTH_ID = 'birthId'
const ANIMAL_TIMELINE_MANAGEMENT_NO = 'managementNumber'
const ANIMAL_TIMELINE_BULL_CODE = 'bullCode'

const SUBMITTED_ID_TYPE_AHB_NUMBER = 'urn:lic:id:ahb_number'
const SUBMITTED_ID_TYPE_BIRTH_ID = 'urn:lic:id:birth_id'
const SUBMITTED_ID_TYPE_BULL_CODE = 'urn:lic:id:bull_code'
const SUBMITTED_ID_TYPE_CASUAL_NAME = 'urn:lic:id:casual_name'
const SUBMITTED_ID_TYPE_MGMT_NO = 'urn:lic:id:management_number'
const SUBMITTED_ID_TYPE_UNKNOWN = 'urn:lic:id:unknown_type'

const ID_TYPE_LABEL_MAPPINGS = {
  [ANIMAL_TIMELINE_AHB_NUMBER]: 'AHB Number',
  [ANIMAL_TIMELINE_BIRTH_ID]: 'Birth ID',
  [ANIMAL_TIMELINE_MANAGEMENT_NO]: 'Management number',
  [ANIMAL_TIMELINE_BULL_CODE]: 'Bull code',
}

// Maps Animal Timeline ID types (strings) to Sample Management URNs
const ID_TYPE_SAMPLE_MGMT_URN_MAPPINGS = {
  [ANIMAL_TIMELINE_AHB_NUMBER]: SUBMITTED_ID_TYPE_AHB_NUMBER,
  [ANIMAL_TIMELINE_BIRTH_ID]: SUBMITTED_ID_TYPE_BIRTH_ID,
  [ANIMAL_TIMELINE_MANAGEMENT_NO]: SUBMITTED_ID_TYPE_MGMT_NO,
  [ANIMAL_TIMELINE_BULL_CODE]: SUBMITTED_ID_TYPE_BULL_CODE,
}

// Maps Animal Timeline ID types (strings) to input masks
const ID_TYPE_MASK_MAPPINGS = {
  [ANIMAL_TIMELINE_BIRTH_ID]: MASK_TYPES.BIRTH_ID,
  [ANIMAL_TIMELINE_BULL_CODE]: MASK_TYPES.BULL_CODE,
  [ANIMAL_TIMELINE_MANAGEMENT_NO]: MASK_TYPES.MANAGEMENT_NUMBER,
}

export {
  ANIMAL_TIMELINE_BIRTH_ID,
  ANIMAL_TIMELINE_BULL_CODE,
  ANIMAL_TIMELINE_MANAGEMENT_NO,
  ID_TYPE_LABEL_MAPPINGS,
  ID_TYPE_MASK_MAPPINGS,
  ID_TYPE_SAMPLE_MGMT_URN_MAPPINGS,
  SUBMITTED_ID_TYPE_AHB_NUMBER,
  SUBMITTED_ID_TYPE_BIRTH_ID,
  SUBMITTED_ID_TYPE_BULL_CODE,
  SUBMITTED_ID_TYPE_CASUAL_NAME,
  SUBMITTED_ID_TYPE_MGMT_NO,
  SUBMITTED_ID_TYPE_UNKNOWN,
}
