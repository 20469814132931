import Decimal from 'decimal.js/decimal'

export function titleCase(string) {
  return string.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function convertUnderscoresToSpaces(string) {
  return string.split('_').join(' ')
}

export function sentenceCase(string) {
  // A naive implementation only for a single sentence. Only used for labels so this should be ok.
  if (string.includes('_')) string = convertUnderscoresToSpaces(string)
  let lc = string.toLowerCase()
  return lc[0].toUpperCase() + lc.slice(1)
}

export function trimUnderscore(str) {
  return str ? str.replace(/_/g, '').trim() : ''
}

export function startsWithLetter(val) {
  return val && val.length && val[0].match(/[a-zA-Z]/)
}

export function startsWithNumber(val) {
  return val && val.length && val[0].match(/\d/)
}

export function formattedPrice(price) {
  return price
    ? new Decimal(Math.abs(price)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : new Decimal(0).toFixed(2)
}

// https://github.com/apollographql/apollo-client/pull/3892 - allows us to remove the 'GraphQL Error' text from errors
export function parseGraphQLError(string) {
  return string.replace(/GraphQL error: /gi, '')
}
