import React from 'react'
import PropTypes from 'prop-types'

import { useAbilities } from 'lib/components/AbilitiesProvider'

import RequestWrapper from '@licnz/react-request-wrapper'
import UnauthorisedPanel from 'lib/components/layout/UnauthorisedPanel'

const AbilitiesWrapper = ({
  ability,
  children,
  history,
  location,
  quantifier = 'and',
  showMessage = true,
}) => {
  const { can, loading } = useAbilities(ability, quantifier)

  const renderMessage = () => {
    return showMessage ? (
      <UnauthorisedPanel history={history} location={location} />
    ) : null
  }

  return (
    <RequestWrapper loading={loading}>{can ? children : renderMessage()}</RequestWrapper>
  )
}

AbilitiesWrapper.propTypes = {
  ability: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  quantifier: PropTypes.oneOf(['and', 'or']),
  showMessage: PropTypes.bool,
}

export default AbilitiesWrapper
