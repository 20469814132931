import axios from 'lib/axios'
import queryString from 'query-string'

const getMultipleLinks = (_key, links) => {
  return Promise.all(
    links.map(link => axios.get(proxyUrl({ link })).then(response => response.data))
  )
}

function proxyUrl({ link, params }) {
  const PROXY_ENDPOINT = `${global.config.UI_PROXY_ENDPOINT}/api/proxy`
  let urlToProxy = params ? `${link}?${queryString.stringify(params)}` : link

  return `${PROXY_ENDPOINT}/${encodeURIComponent(urlToProxy)}`
}

export { getMultipleLinks, proxyUrl }
