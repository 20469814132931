import { trimUnderscore } from 'utils/util'

/**
 * This function returns a dynamic length mask with _no_ guides and _no_ leading zeros.
 * i.e. it returns a numeric mask with the same length as the passed in value.
 *
 */
const numericMask = ({ val, maxLength }) => {
  let valLength = Number.parseInt(val.replace(/\D/, '')).toString().length
  return valLength
    ? [/[1-9]/, ...Array(Math.min(valLength - 1, maxLength - 1)).fill(/\d/)]
    : []
}

const bullCodeMask = val => numericMask({ val, maxLength: 6 })

const managementNumberMask = val => numericMask({ val, maxLength: 5 })

const numericIdMask = val => numericMask({ val, maxLength: 6 })

const birthIdMask = val => {
  let mask = [
    ...participantCodeMask(val),
    '-',
    ...yearMask(val),
    '-',
    ...sequenceMask(val),
  ]
  // when sequence has been entered and an alphabetic character is input, add it as a suffix. This birth ID is sent to backend as a daisy ID
  const birthIdTokens = trimUnderscore(val).split('-')
  if (birthIdTokens[3] || (birthIdTokens[2] && birthIdTokens[2].search(/[a-zA-Z]/) > 0)) {
    mask = [...mask, '-', ...suffixMask(val)]
  }
  return mask
}

/**
 * The last chunk of numbers can be one or more digits, but we don't want to
 * allow the user to go too crazy, so we use sequenceMask which has a `maxLength` of 5.
 */
const ahbNumberMask = val => {
  let mask = [
    ...numericMask({ val, maxLength: 7 }),
    '-',
    ...numericMask({ val, maxLength: 2 }),
    '-',
    ...sequenceMask(val),
  ]

  return mask
}

/**
 * It's not pretty but this supports a variable length (from `minLength` to `maxLength`),
 * alphabetic participant code. It will allow a single numeric value to be entered after
 * a valid participant code; when a numeric value is entered the alphabetic mask is
 * terminated and the number is not handled (the next section of the mask should take over)
 */
const participantCodeMask = val => {
  let mask = [/[a-zA-Z]/, /[a-zA-Z]/]
  const minLength = 2
  const maxLength = 5
  const birthIdTokens = trimUnderscore(val).split('-')

  let ptptToken = birthIdTokens.length > 0 ? birthIdTokens[0] : null
  let yearToken = birthIdTokens.length > 1 ? birthIdTokens[1] : null

  if (ptptToken) {
    let ptptCode =
      ptptToken.search(/\d/) < 0 ? ptptToken : ptptToken.slice(0, ptptToken.search(/\d/))

    if (ptptCode.length >= minLength) {
      if (
        ptptToken.length !== ptptCode.length ||
        ptptCode.length >= maxLength ||
        !!yearToken
      ) {
        mask = Array(Math.min(ptptCode.length, maxLength)).fill(/[a-zA-Z]/)
      } else {
        mask = [...Array(ptptCode.length).fill(/[a-zA-Z]/), /[a-zA-Z|\d]/]
      }
    }
  }

  return mask
}

// eslint-disable-next-line no-unused-vars
const yearMask = val => {
  return [/\d/, /\d/]
}

const sequenceMask = val => {
  let mask = [/\d/]
  const maxLength = 5
  const identifierTokens = trimUnderscore(val).split('-')

  let numberToken = identifierTokens.length >= 3 ? identifierTokens[2] : null
  if (numberToken) {
    mask = numericMask({ val: numberToken, maxLength })
  }

  return mask
}

// eslint-disable-next-line no-unused-vars
const suffixMask = val => {
  return [/[a-zA-Z]/]
}

export { ahbNumberMask, birthIdMask, bullCodeMask, managementNumberMask, numericIdMask }
