import React from 'react'
import PropTypes from 'prop-types'

import Button from 'lib/components/Button'
import SectionHeading from 'lib/components/headings/SectionHeading'
import { PaddedBlock } from '@licnz/react-layout'
import ReactModal from 'react-modal'

import styles from './styles'

const ConfirmationDialog = ({
  cancelLabel,
  Component,
  confirmLabel,
  description,
  isOpen,
  onCancel,
  onConfirm,
  title,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={`${styles.overlay} ${styles.showOverlay}`}
      className={styles.content}
      ariaHideApp={false}
    >
      <PaddedBlock border={false}>
        {title ? <SectionHeading title={title} /> : null}
        {description}
        {Component ? (
          <Component onConfirm={onConfirm} onCancel={onCancel} />
        ) : (
          <div className={styles.buttonWrapper}>
            <Button className='buttonOutline' onClick={onCancel}>
              {cancelLabel}
            </Button>
            <Button className='buttonPrimary' onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </div>
        )}
      </PaddedBlock>
    </ReactModal>
  )
}

ConfirmationDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Ok',
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ConfirmationDialog
