import React, { lazy, Suspense } from 'react'
import queryString from 'query-string'

import { Redirect, Route, Switch, generatePath } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import RequestWrapper from '@licnz/react-request-wrapper'

const Bookings = lazy(() =>
  import(/* webpackChunkName: 'Bookings' */ 'components/Bookings')
)
const Internals = lazy(() =>
  import(/* webpackChunkName: 'Bookings' */ 'components/Internals')
)
const InternalsErrors = lazy(() =>
  import(/* webpackChunkName: 'Bookings' */ 'components/InternalsErrors')
)
const SampleScanning = lazy(() =>
  import(/* webpackChunkName: 'SampleScanning' */ 'components/SampleScanning')
)
const SampleScanningErrors = lazy(() =>
  import(
    /* webpackChunkName: 'SampleScanningErrors' */ 'components/SampleScanning/SampleScanningErrors'
  )
)
const SubmissionCoversheet = lazy(() =>
  import(/* webpackChunkName: 'SubmissionCoversheet' */ 'components/SubmissionCoversheet')
)
const SubmissionReceipting = lazy(() =>
  import(/* webpackChunkName: 'SubmissionReceipting' */ 'components/SubmissionReceipting')
)
const SubmissionSummary = lazy(() =>
  import(/* webpackChunkName: 'SubmissionSummary' */ 'components/SubmissionSummary')
)
const VialScanning = lazy(() =>
  import(/* webpackChunkName: 'VialScanning' */ 'components/VialScanning')
)
const VialScanningErrors = lazy(() =>
  import(
    /* webpackChunkName: 'VialScanningErrors' */ 'components/VialScanning/VialScanningErrors'
  )
)

/**
 * A utility function for working with React Router paths. It allows us to both define
 * routes and build valid links to those routes with the same function, making it easier
 * to link to, search for, and refactor our routes (no more magic strings!)
 *
 * When called with only a `path`, it will return that path. This is useful when defining
 * a route, or creating a link to a simple, non-parameterised route.
 *
 * When called with `routeParams`, it will return a link to the path, substituting the
 * route param values for the placeholders in the path.
 *
 * When called with `queryParams`, it will return a link to the path, including a
 * queryString containing the query params.
 *
 * When called with `referer`, it will return the path as a `location` object containing
 * the referer in its `state` object.
 */
const buildLink = ({ path, routeParams, queryParams, referer }) => {
  let pathname = routeParams ? generatePath(path, routeParams) : path
  let search = queryParams ? `?${queryString.stringify(queryParams)}` : ''

  if (referer) {
    return {
      pathname,
      search,
      state: { referer },
    }
  }

  return `${pathname}${search}`
}

// See `buildLink` above for valid `args`
const bookingsPath = args => buildLink({ ...args, path: '/bookings' })
const internalsErrorsPath = args =>
  buildLink({ ...args, path: '/bookings/:submissionId/dna_internals_errors' })
const internalsPath = args =>
  buildLink({ ...args, path: '/bookings/:submissionId/dna_internals' })
const s1ScanningPath = args => buildLink({ ...args, path: `/bookings/:submissionId/s1` })
const s2ScanningPath = args => buildLink({ ...args, path: `/bookings/:submissionId/s2` })
const sampleScanningErrorsPath = args =>
  buildLink({ ...args, path: '/bookings/:submissionId/sample_scanning_errors' })
const submissionCoversheetPath = args =>
  buildLink({ ...args, path: `/bookings/:submissionId/coversheet` })
const submissionReceiptingPath = args =>
  buildLink({ ...args, path: '/bookings/:reservationId/receipting' })
const submissionSummaryPath = args =>
  buildLink({ ...args, path: `/bookings/:submissionId/summary` })
const vialScanningErrorsPath = args =>
  buildLink({ ...args, path: '/bookings/:submissionId/vial_errors' })
const vialScanningPath = args =>
  buildLink({ ...args, path: `/bookings/:submissionId/vial` })

/*
 *  We have two types of routes here:
 *    1. Route - a standard (public) client side route.
 *    2. ProtectedRoute - a custom wrapper on the standard Route component.
 *  The ProtectedRoute should be used for all routes that require the user to be
 *  logged in. It checks the current loggedIn status and either renders the
 *  component at this route, or redirects back to the `/guest` route.
 */

const Routes = () => (
  <Suspense fallback={<RequestWrapper loading={true} />}>
    <Switch>
      <ProtectedRoute
        path={sampleScanningErrorsPath()}
        component={SampleScanningErrors}
      />
      <ProtectedRoute
        path={submissionReceiptingPath()}
        component={SubmissionReceipting}
      />
      <ProtectedRoute path={internalsErrorsPath()} component={InternalsErrors} />
      <ProtectedRoute path={internalsPath()} component={Internals} />
      <ProtectedRoute path={s1ScanningPath()} component={SampleScanning} />
      <ProtectedRoute path={s2ScanningPath()} component={SampleScanning} />
      <ProtectedRoute path={vialScanningErrorsPath()} component={VialScanningErrors} />
      <ProtectedRoute path={vialScanningPath()} component={VialScanning} />
      <ProtectedRoute
        path={submissionCoversheetPath()}
        component={SubmissionCoversheet}
      />
      <ProtectedRoute path={submissionSummaryPath()} component={SubmissionSummary} />
      <ProtectedRoute path={bookingsPath()} component={Bookings} />
      <Redirect exact from='/' to={bookingsPath()} />
    </Switch>
  </Suspense>
)

const RootRoute = () => <Route path='/' component={Routes} />

export default RootRoute
export {
  Routes,
  bookingsPath,
  internalsPath,
  internalsErrorsPath,
  s1ScanningPath,
  s2ScanningPath,
  sampleScanningErrorsPath,
  submissionCoversheetPath,
  submissionReceiptingPath,
  submissionSummaryPath,
  vialScanningErrorsPath,
  vialScanningPath,
}
