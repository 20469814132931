import React from 'react'
import axios from 'axios'
import { render } from 'react-dom'
import { version } from '../package.json'

import createStore from './store/createStore'
import initialiseExceptionMonitoring from '../config/exceptionMonitoring'

import { ALL_ABILITIES } from 'constants/abilityTypes'

import ApolloClientWrapper from 'components/ApolloClientWrapper'
import AppRoot from 'components/AppRoot'
import AuthHandler from 'components/AuthHandler'
import ConfiguredReactQueryProvider from 'components/ConfiguredReactQueryProvider'
import withUser from 'lib/components/AbilitiesProvider/withUser'
import { AbilitiesProvider } from 'lib/components/AbilitiesProvider'
import { ConfirmationProvider } from 'lib/components/ConfirmationDialog'
import { Provider } from 'react-redux'
import { ReactQueryDevtools } from 'react-query-devtools'

const store = createStore()
const WrappedAbilitiesProvider = withUser(AbilitiesProvider)

const renderApp = () =>
  render(
    <Provider store={store}>
      <ConfiguredReactQueryProvider>
        <WrappedAbilitiesProvider resourceIdentifiers={ALL_ABILITIES}>
          <AuthHandler />
          <ApolloClientWrapper>
            <ConfirmationProvider>
              <AppRoot />
            </ConfirmationProvider>
          </ApolloClientWrapper>
        </WrappedAbilitiesProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </ConfiguredReactQueryProvider>
    </Provider>,
    document.querySelector('#root')
  )

const getAppConfig = () => {
  // Set to the current version number to force a re-read of the config when version changes
  return axios.get(`./config.json?version=${version}`).then(response => response.data)
}

getAppConfig().then(config => {
  global.config = config
  initialiseExceptionMonitoring(config)
  renderApp()
})
