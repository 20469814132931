import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { trimmedBirthId, isBirthIdWithoutNumber } from 'utils/animalIds'
import { startsWithLetter, startsWithNumber } from 'utils/util'
import keyCodes from 'constants/keyCodes'
import {
  ahbNumberMask,
  birthIdMask,
  bullCodeMask,
  managementNumberMask,
  numericIdMask,
} from './masks'

import MaskedInput from 'react-text-mask'

import styles from './styles'

const MASK_TYPES = {
  AHB_NUMBER: 'ahbIdentifier',
  BULL_CODE: 'bullCode',
  BIRTH_ID: 'birthId',
  MANAGEMENT_NUMBER: 'managementNumber',
}

const MASKS = {
  [MASK_TYPES.AHB_NUMBER]: ahbNumberMask,
  [MASK_TYPES.BULL_CODE]: bullCodeMask,
  [MASK_TYPES.MANAGEMENT_NUMBER]: managementNumberMask,
  [MASK_TYPES.BIRTH_ID]: birthIdMask,
}

/**
 * Renders a controlled input field with dynamic masking for animal IDs
 * (supports Birth IDs, Management numbers and Bull codes).
 * Animal ID type is determined by the first character entered:
 *  - number = management number/bull code
 *  - letter = birth id.
 * The mask length is dynamic when entering numeric data
 */
const MaskedAnimalIdInput = ({
  className,
  hasErrors,
  inputId,
  idType,
  onChange,
  onEnter,
  onDelete,
  disabled,
  placeholder,
  value,
}) => {
  useEffect(() => {
    if (value) {
      const input = document.getElementById(inputId)
      if (input && isBirthIdWithoutNumber(input.value)) {
        // set cursor to end of prefill value
        input.setSelectionRange(input.value.length, -1)
      }
    }
  }, [value, inputId])

  const getMask = val => {
    let mask = [/[a-zA-Z]|\d/]

    if (startsWithLetter(val)) mask = birthIdMask(val)
    else if (startsWithNumber(val) && val.length < 7) mask = numericIdMask(val)
    else if (startsWithNumber(val)) mask = ahbNumberMask(val)

    return mask
  }

  const handleKeyUp = e => {
    let currentValue = e.target.value

    if (e.keyCode === keyCodes.DELETE) onDelete()
    if (e.keyCode === keyCodes.ENTER) {
      let animalId =
        currentValue && currentValue.length > 0 ? trimmedBirthId(currentValue) : null
      animalId && onEnter(animalId)
    }
  }

  const handleChange = e => {
    onChange(e.target.value.toUpperCase())
  }

  return (
    <MaskedInput
      data-testid='maskedInput'
      className={classnames(styles.animalIdInput, {
        [className]: !!className,
        [styles.error]: hasErrors,
      })}
      guide={!disabled}
      onKeyUp={handleKeyUp}
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      mask={idType ? MASKS[idType] : getMask}
      disabled={disabled}
      render={(ref, props) => (
        <input autoComplete='off' ref={ref} {...props} id={inputId} />
      )}
    />
  )
}

MaskedAnimalIdInput.defaultProps = {
  disabled: false,
  hasErrors: false,
  isBirthId: false,
  onChange: () => {},
  onDelete: () => {},
  onEnter: () => {},
}

MaskedAnimalIdInput.propTypes = {
  className: PropTypes.string,
  hasErrors: PropTypes.bool,
  idType: PropTypes.oneOf(Object.values(MASK_TYPES)),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

export default MaskedAnimalIdInput
export { MASK_TYPES }
